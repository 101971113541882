<template>
  <CFooter :fixed="false" class="enrich-footer">
    <div class="mfs-auto enrich-footer-item">
      <span class="ml-1">Enrich CRM &copy; {{new Date().getFullYear()}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style scoped>

.enrich-footer-item {
    color: white;
}

</style>