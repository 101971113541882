<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <a-icon type="user" :style="{ fontSize: '26px' }" class="enrich-header-item"/>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <div v-if="loggedIn" :key="'LoggedIn-' + loggedIn">
    <CDropdownItem>
      <p @click="logout()">Logout</p>
    </CDropdownItem>
    </div>
    <div v-else>
    <CDropdownItem>
      <router-link class="nav-link" to="/login2">
        Login
      </router-link>
    </CDropdownItem>
    </div>
  </CDropdown>
</template>

<script>
// import cognitoAuth from '@/cognito'
import enrichAuth from '@/auth'


export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      loggedIn: false
    }
  },
  methods: {
    isLoggedIn() {
      console.log('TheHeaderDropdownAccbt: isLoggedIn: ')
      enrichAuth.isLoggedIn((err, authenticated) => {
        if (err) this.loggedIn = false
        this.loggedIn = authenticated
        console.log('TheHeaderDropdownAccbt: loggedIn: ' + this.loggedIn)
      })
    },
    logout() {
      console.log('TheHeaderDropdownAccbt: about to log out...')
      enrichAuth.logout()
      this.isLoggedIn()
      this.$router.push({path: `/login2`})
    }

  },
  mounted() {
    console.log('TheHeaderDropdownAccnt: mounted')
    this.isLoggedIn()
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }

  .enrich-header-item {
    color: white;
  }
</style>

