<template>
  <CHeader fixed with-subheader class="enrich-header">
    <CHeaderNav class="ml-3">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
        <div class="c-avatar">
          <a-icon type="home" :style="{ fontSize: '26px' }" class="enrich-header-item"/>
        </div>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderBrand class="mx-auto enrich-header-item" to="/">
      Enrich CRM
    </CHeaderBrand>
    <CHeaderNav class="mr-3">
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  }
}
</script>

<style scoped>

.enrich-header-item {
    color: white;
    font-size: x-large;
}
</style>